import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const TITLE_DEFAULT = "Growth Center";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "login",
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/update-profile",
    name: "UpdateProfile",
    component: () =>
      import(
        /* webpackChunkName: "update-profile" */ "../views/UpdateProfile/index.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/detail/:id",
    name: "TestDetail",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/test/Detail/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/report/:bundleId/:resultId",
    name: "TestReport",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/test/Report/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/practice/:id",
    name: "TestPractice",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/test/Practice/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/live/:id",
    name: "TestLive",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/test/Live/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test/live/multifacet/:id",
    name: "TestLiveMultifacet",
    component: () =>
      import(
        /* webpackChunkName: "test" */ "../views/test/Live/Multifacet/index.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/login/index.vue"),
  },
  {
    path: "/admin/",
    name: "AdminContainer",
    component: () => import("../views/admin/Container.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "/admin/home",
        name: "AdminHome",
        component: () => import("../views/admin/Home.vue"),
        meta: {
          title: `Projek Saya | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/create",
        name: "AdminCreate",
        component: () => import("../views/admin/project/Create.vue"),
        meta: {
          title: `Buat Proyek | ${TITLE_DEFAULT}`,
          isPlainLayout: true,
        },
      },
      {
        path: "/admin/project/:projectId",
        name: "AdminProjectDetail",
        component: () => import("../views/admin/project/Detail.vue"),
        meta: {
          title: `Detail Proyek | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/:projectId/test/:testId",
        name: "AdminProjectTestDetail",
        component: () => import("../views/admin/project/TestDetail.vue"),
        meta: {
          title: `Detail Asesmen | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/:projectId/participant/:participantId",
        name: "AdminProjectParticipantDetail",
        component: () => import("../views/admin/project/ParticipantDetail.vue"),
        meta: {
          title: `Detail Peserta | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/:projectId/edit",
        name: "AdminProjectEdit",
        component: () => import("../views/admin/project/Edit.vue"),
        meta: {
          title: `Edit Proyek | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/:projectId/invite/confirmation",
        name: "AdminProjectInviteConfirmation",
        component: () =>
          import("../views/admin/project/InviteConfirmation.vue"),
        meta: {
          title: `Konfirmasi | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/invite/invoice",
        name: "AdminProjectInviteInvoice",
        component: () => import("../views/admin/project/InviteInvoice.vue"),
        meta: {
          title: `Bukti Pembayaran | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/token-history",
        name: "AdminTokenHistory",
        component: () => import("../views/admin/token/TokenHistory.vue"),
        meta: {
          title: `Riwayat Pembelian | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/token-history/:historyId",
        name: "AdminTokenHistoryDetail",
        component: () => import("../views/admin/token/TokenHistoryDetail.vue"),
        meta: {
          title: `Riwayat Pembelian Detail | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/admin/project/:projectId/invite",
        name: "AdminProjectInviteParticipant",
        component: () => import("../views/admin/project/InviteParticipant.vue"),
        meta: {
          title: `Tambah Peserta | ${TITLE_DEFAULT}`,
          isPlainLayout: true,
        },
      },
      {
        path: "/admin/customize-email",
        name: "CustomizeEmail",
        component: () => import("../views/admin/CustomizeEmail.vue"),
        meta: {
          title: `Customize Email | ${TITLE_DEFAULT}`,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        component: () => import("../views/admin/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Meta Handling
router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth) {
    // Authenticated user
    if (!localStorage.getItem("authData")) {
      if (to.meta.isAdmin) next({ name: "AdminLogin" });
      else next({ name: "Login" });
    } else {
      const user = JSON.parse(localStorage.getItem("authData") ?? "");
      if (user.isAdmin && to.meta.isAdmin) {
        next();
      } else if (user.isAdmin && !to.meta.isAdmin) {
        next({ name: "AdminHome" });
      } else if (!user.isAdmin && !to.meta.isAdmin) {
        next();
      } else if (!user.isAdmin && to.meta.isAdmin) {
        next({ name: "Home" });
      }
    }
  } else if (to.meta.guest) {
    // Unauthenticated user
    if (!localStorage.getItem("authData")) {
      next();
    } else {
      const user = JSON.parse(localStorage.getItem("authData") ?? "");
      if (user.isAdmin) {
        next({ name: "AdminHome" });
      } else {
        next({ name: "Home" });
      }
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  document.title = (to.meta.title as string) || TITLE_DEFAULT;
});

export default router;
